import React from "react";

const SvgWebsite = ({
  style = {},
  fill = "#fff",
  width = "100%",
  className = "ico ico-1 brief-website",
  height = "100%",
  viewBox = "0 0 126 119.1"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    x="0px"
    y="0px"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#9740FF"
      d="M105.5,0C94.2,0,85,9.2,85,20.5S94.2,41,105.5,41S126,31.8,126,20.5c0,0,0,0,0,0C126,9.2,116.8,0,105.5,0"
    />
    <path
      fill="#403538"
      d="M113.2,100.9H72c-0.7,0-1.3,0.4-1.6,1l-1.9,3.8h-22l-1.9-3.8c-0.3-0.6-0.9-1-1.6-1H1.8c-1,0-1.8,0.8-1.8,1.8
	v12.1c0,2.3,1.9,4.2,4.2,4.2h106.5c2.3,0,4.2-1.9,4.2-4.2v-12.1C115,101.8,114.2,100.9,113.2,100.9z M111.4,115.5H3.6v0l0,0h0v-10.9
	h38.2l1.9,3.8c0.3,0.6,0.9,1,1.6,1h24.2c0.7,0,1.3-0.4,1.6-1l1.9-3.8h38.2V115.5z"
    />
    <polygon fill="#403538" points="3.6,115.5 3.6,115.5 3.6,115.5 " />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#403538"
      d="M4.8,94.9V35c0-3.7,3-6.7,6.7-6.7h26v3.6h-26c-1.7,0-3,1.4-3,3v59.9H4.8z"
    />
    <rect
      x="106.5"
      y="71.9"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#403538"
      width="3.6"
      height="23"
    />
    <rect
      x="96.8"
      y="79.1"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#403538"
      width="3.6"
      height="15.7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#403538"
      d="M14.5,94.9V39.8c0-1,0.8-1.8,1.8-1.8h21.2v3.6H18.2v53.3L14.5,94.9L14.5,94.9z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#403538"
      d="M24.2,56.1v-6.7c0-1,0.8-1.8,1.8-1.8h6.7v3.6h-4.8v4.8L24.2,56.1L24.2,56.1z"
    />
    <rect
      x="9.7"
      y="108.2"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#403538"
      width="3.6"
      height="3.6"
    />
    <rect
      x="101.7"
      y="108.2"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#403538"
      width="3.6"
      height="3.6"
    />
    <path
      fill="#403538"
      d="M79.3,4.1c-19.7,0-35.7,16-35.7,35.7c0,19.7,16,35.7,35.7,35.7h0c19.7,0,35.7-16,35.7-35.7
	C115,20.1,99,4.1,79.3,4.1z M79.3,71.9c-17.7,0-32.1-14.4-32.1-32.1c0-17.7,14.4-32.1,32.1-32.1c17.7,0,32.1,14.4,32.1,32.1
	C111.4,57.5,97,71.9,79.3,71.9z"
    />
    <path
      className="move-left"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#403538"
      d="M58.6,41.1c-0.7-0.7-0.7-1.9,0-2.6l9.3-9.3l2.6,2.6l-8,8l8,8l-2.6,2.6L58.6,41.1z"
    />
    <path
      className="move-right"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#403538"
      d="M88.1,47.8l8-8l-8-8l2.6-2.6l9.3,9.3c0.7,0.7,0.7,1.9,0,2.6l-9.3,9.3L88.1,47.8z"
    />
  </svg>
);
export default SvgWebsite;
