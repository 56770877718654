import React from "react";

const SvgBranding = ({
  isShaking,
  width = "100%",
  className = "ico ico-3 brief-branding",
  height = "100%",
  viewBox = "-10 0 134 129"
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    x="0px"
    y="0px"
  >
    <path
      id="Circle_2_copy"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#6DDFFA"
      d="M62,46c-12.7,0-23-10.3-23-23S49.3,0,62,0s23,10.3,23,23C85,35.7,74.7,46,62,46z M62,9.6
	c-7.4,0-13.3,6-13.3,13.3c0,7.4,6,13.3,13.3,13.3c7.4,0,13.3-6,13.3-13.3C75.3,15.6,69.4,9.7,62,9.6z"
    />

    <g className={`${isShaking ? "shake-slow shake-constant" : ""}`}>
      <path
        className="animation"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#403538"
        d="M112.9,24.6l-12.4,6.9c-1.1,0.6-2.4,0.2-3-0.9s-0.2-2.4,0.9-3l12.4-6.9c1.1-0.6,2.4-0.2,3,0.9
	S113.9,24,112.9,24.6z"
      />
      <path
        fill="#403538"
        d="M93.1,64.2l-7.4-12.8l4.7-7.2c0.5-0.7,0.5-1.6,0.1-2.3L86,34.2C85.6,33.4,84.8,33,84,33l-8.6,0.5l-8.2-14.2
	C66,17.3,63.9,16,61.5,16h-0.1c-2.3,0-4.4,1.2-5.6,3.1L26.7,56.1L7.8,67.1C1.1,71-1.8,79.2,1.1,86.4c3.2,8,12.3,11.9,20.3,8.7
	l11.2,19.4c2.5,4.3,7.9,5.7,12.2,3.3c4.3-2.5,5.7-7.9,3.3-12.2L41.3,94l5.8-3.3c1.1-0.6,1.4-2,0.8-3l-2.9-5l42.7-8.4h0
	c1-0.1,2.1-0.4,3-0.9C93.8,71.5,94.9,67.4,93.1,64.2z M82.9,37.6l3.1,5.3l-2.7,4.2l-5.3-9.2L82.9,37.6z M21.1,90.2
	c-5.3,3-12,1.2-15.1-4.1C2.9,80.9,4.7,74,10,70.9l17.3-10l11.1,19.3L21.1,90.2z M44.1,107.8C44.2,107.9,44.2,107.9,44.1,107.8
	c1.3,2.2,0.5,4.9-1.6,6.1s-4.9,0.5-6.1-1.7L25.3,93l7.7-4.5C45.9,110.9,43.9,107.5,44.1,107.8z M39.1,90.2l-2.2-3.9l3.8-2.2l2.2,3.9
	L39.1,90.2z M42.7,78.5L30.9,58.1l24.9-31.8l25.7,44.6L42.7,78.5z M87.4,69.8c-0.4,0-0.8,0.1-1.2,0.2L58.8,22.5
	c0.6-0.7,0.7-0.8,0.8-1c0.2-0.3,0.5-0.6,0.8-0.8c1.1-0.6,2.4-0.2,3,0.8l25.9,44.9v0c0.2,0.3,0.3,0.7,0.3,1.1
	C89.6,68.7,88.6,69.8,87.4,69.8z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#403538"
        d="M30.5,79.7c-1.1,0.6-2.4,0.2-3-0.8s-0.2-2.4,0.8-3c1.1-0.6,2.4-0.2,3,0.8C31.9,77.7,31.6,79.1,30.5,79.7
	C30.5,79.7,30.5,79.7,30.5,79.7z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#403538"
        d="M22.8,84.2l-3.8,2.2c-3.2,1.8-7.3,0.7-9.1-2.4c-0.6-1.1-0.3-2.4,0.8-3.1c1.1-0.6,2.4-0.3,3.1,0.8c0,0,0,0,0,0.1
	c0.6,1.1,2,1.4,3,0.8c0,0,0,0,0,0l3.8-2.2c1.1-0.6,2.4-0.3,3.1,0.7C24.3,82.1,23.9,83.5,22.8,84.2C22.8,84.1,22.8,84.1,22.8,84.2
	L22.8,84.2z"
      />
      <path
        className="animation"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#403538"
        d="M89.8,22.4c-0.3,1.2-1.6,1.9-2.7,1.6c-1.2-0.3-1.9-1.5-1.6-2.7l2.3-8.6c0.3-1.2,1.5-1.9,2.7-1.6
	c1.2,0.3,1.9,1.5,1.6,2.7l0,0L89.8,22.4z"
      />
      <path
        className="animation"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#403538"
        d="M100.8,41.5l8.6,2.3c1.2,0.3,2,1.4,1.7,2.7c-0.3,1.2-1.4,2-2.7,1.7c-0.1,0-0.1,0-0.2-0.1l-8.6-2.3
	c-1.2-0.3-1.9-1.5-1.7-2.7c0.3-1.2,1.5-1.9,2.7-1.7C100.7,41.5,100.7,41.5,100.8,41.5L100.8,41.5z"
      />
    </g>
  </svg>
);
export default SvgBranding;
