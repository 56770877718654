import React from "react";

const SvgShop = ({
  style = {},
  fill = "#fff",
  width = "100%",
  className = "ico ico-2 breif-shop",
  height = "100%",
  viewBox = "0 0 142 114.9"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    x="0px"
    y="0px"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#EAB941"
      d="M0,88.9l26-26l26,26l-26,26L0,88.9z M26,76.5L13.6,88.9L26,101.3l12.4-12.4L26,76.5z"
    />
    <path
      fill="none"
      d="M28.9,12.3v81.6c0,1.2,1,2.2,2.2,2.2h104.7c1.2,0,2.2-1,2.2-2.2c0,0,0,0,0,0V12.3l0,0H28.9z"
    />
    <path
      fill="#403538"
      d="M135.9,0H31.2C27.8,0,25,2.7,25,6.1v87.7c0,3.4,2.8,6.1,6.1,6.1h104.7c3.4,0,6.1-2.7,6.2-6.1V6.1
	C142,2.7,139.2,0,135.9,0z M133.6,3.9h2.2c0,0,0,0,0,0c1.2,0,2.2,1,2.2,2.2h0v2.2h-4.5V3.9z M54.1,3.9h75.6v4.4H54.1V3.9z M45.7,3.9
	h4.4v4.4h-4.4V3.9z M37.3,3.9h4.5v4.4h-4.5V3.9z M28.9,6.1c0-1.2,1-2.2,2.2-2.2h2.2v4.4h-4.4V6.1z M138.1,93.9
	C138.1,93.9,138.1,93.9,138.1,93.9c0,1.2-1,2.2-2.2,2.2H31.2c-1.2,0-2.2-1-2.2-2.2V12.3h109.1l0,0V93.9z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#403538"
      d="M131.7,20.6H35.3c-1.1,0-2-0.8-2-1.9s0.8-2,1.9-2c0,0,0.1,0,0.1,0h96.3c1.1,0,2,0.8,2,1.9
	S132.8,20.6,131.7,20.6C131.7,20.6,131.7,20.6,131.7,20.6L131.7,20.6z"
    />
    <path
      className="move-down"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#403538"
      d="M85.6,29.2c1.1,0,2,0.9,2,2v22.4l2.9-2.9c0.8-0.8,2-0.7,2.8,0c0.7,0.8,0.7,2,0,2.7L87,59.7
	c-0.8,0.8-2,0.8-2.8,0l-6.3-6.3c-0.8-0.8-0.8-2,0-2.8c0.8-0.8,2-0.8,2.8,0c0,0,0,0,0,0l2.9,2.9V31.2C83.6,30.1,84.5,29.2,85.6,29.2z
	"
    />
    <path
      fill="#403538"
      d="M106.5,41.8H94c-1.1,0-2,0.9-2,2s0.9,2,2,2h10.6v15.2c0,1.1-0.8,2.1-1.9,2.2l-26.6,3.3c-1,0.1-2-0.5-2.4-1.4
	l-7.3-19.3h10.8c1.1,0,2-0.9,2-2s-0.9-2-2-2H65l-1.6-4.4c-0.9-2.4-3.2-4-5.8-4h-3.4c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2h3.4
	c0.9,0,1.8,0.6,2.1,1.4L70,66.4c0.2,0.7,0.6,1.3,1.1,1.8l-3,5c-0.4-0.1-0.9-0.1-1.3-0.1c-2.6,0-5,1.7-5.8,4.2
	c-1.1,3.2,0.7,6.7,3.9,7.8s6.7-0.7,7.8-3.9h23.9c0.6,1.8,2,3.2,3.9,3.9c3.2,1.1,6.7-0.6,7.8-3.9s-0.6-6.7-3.9-7.8
	c-3.2-1.1-6.7,0.6-7.8,3.9H72.6c-0.3-0.7-0.6-1.4-1.1-2l3-5c0.7,0.2,1.4,0.2,2.1,0.1l26.6-3.3c3.1-0.4,5.4-3,5.4-6.1V43.7
	c0,0,0,0,0,0C108.5,42.7,107.6,41.8,106.5,41.8z M66.8,81.5c-1.2,0-2.2-1-2.2-2.2s1-2.2,2.2-2.2h0c1.2,0,2.2,1,2.2,2.2
	C69,80.5,68,81.5,66.8,81.5z M102.3,77c1.2,0,2.2,1,2.2,2.2s-1,2.2-2.2,2.2h0c-1.2,0-2.2-1-2.2-2.2C100.1,78,101.1,77,102.3,77z"
    />
  </svg>
);
export default SvgShop;
