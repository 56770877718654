import React, { useState } from "react";
import { Layout } from "../../components";
import { Link } from "gatsby";
import {
  SvgWebsite,
  SvgShop,
  SvgBranding,
  SvgBrand,
} from "../../components/brief";

const Brief = ({ location: { pathname } }) => {
  const [isShaking, shake] = useState(false);
  return (
    <Layout
      seo={{
        title: "Brief",
        href: pathname,
        lang: "en",
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/brief/",
      }}
    >
      <section className="container-fluid brief_main_section">
        <h1>Welcome</h1>
        <p className="desc">
          Filling in the form below will enable us to prepare an offer tailored to your needs. <br />
          The entire process should not take you more than 20 minutes.
        </p>
        <h4>Choose the product or service you are looking for</h4>

        <div className="row">
          <div className="col-lg-3 col-sm-6">
            <Link
              to="/en/brief-website/"
              className="brief_link brief_link__website"
            >
              <SvgWebsite />
              <p>Website</p>
            </Link>
          </div>

          <div className="col-lg-3 col-sm-6">
            <Link to="/en/brief-e-comerce" className="brief_link brief_link__shop">
              <SvgShop />
              <p>E-commerce</p>
            </Link>
          </div>

          <div className="col-lg-3 col-sm-6">
            <Link
              to="/en/brief-campaign/"
              className="brief_link brief_link__branding"
              onMouseEnter={() => shake(true)}
              onMouseLeave={() => shake(false)}
            >
              <SvgBranding isShaking={isShaking} />
              <p>
                Comprehensive <br /> marketing campaign
              </p>
            </Link>
          </div>

          <div className="col-lg-3 col-sm-6">
            <Link
              to="/en/brief-branding/"
              className="brief_link brief_link__branding"
            >
              <SvgBrand />
              <p>Branding</p>
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Brief;
