import React from "react";

const BriefNav = ({ currentStep, setStep, steps }) => {
  return (
    <div className={`brief_nav ${currentStep === 5 ? "hidden" : ""}`}>
      {/* eslint-disable jsx-a11y/control-has-associated-label */}
      <ul className="brief_nav__row">
        {Array(steps)
          .fill(null)
          .map((item, index) => (
            <li
              key={index}
              className={`brief_nav__item ${
                index + 1 <= currentStep ? "active" : ""
              }`}
            >
              <button
                type="button"
                key={index}
                onClick={() => setStep(index + 1)}
                onKeyDown={() => setStep(index + 1)}
              />
            </li>
          ))}
      </ul>
    </div>
  );
};

export default BriefNav;
