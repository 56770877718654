import React from "react";
import { Link } from "gatsby";

const Thanks = () => {
  return (
    <div className="wpcf7 step_5 stepWrapper">
      <img
        className="img-fluid"
        src={require("../../assets/img/brief_thank_you.png")}
        alt=""
      />
      <p className="desc">
        Zapoznamy się z Twoimi oczekiwaniami najszybciej jak to możliwe,
        dobierając właściwe narzędzia do realizacji celu, który został nam
        postawiony w wypełnionym briefie. Do usłyszenia!
      </p>
      <p>
        <Link to="/" className="btn_custom">
          Strona główna
        </Link>
      </p>
    </div>
  );
};

export default Thanks;
